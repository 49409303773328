import React from 'react';
import { getRequest } from 'Services/http/core__axios';
import PropTypes from 'prop-types';
import { PromotionsGridContainer } from 'UI/apps/PromotionsGridApp/PromotionsGridReact';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PubSub from 'Services/pubsub/core__pubsub';
import { hideCategoryMenuList } from 'Services/globalstyles/core__globalstyles';
import { getCookie } from 'Services/cookie/core__cookies';
import { PromotionsContainer } from './components/PromotionsContainer/core__promotionsContainer';
import { FSBTheme } from 'Services/core__fsb-theme';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { HnaldeDefaultCurrency } from 'Services/global/core__currency-format';

class PromotionsGridReact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items:
        props?.appConfig?.items?.filter(itm => !itm?.appConfig?.auth) ?? [],
      appid: props?.appConfig?.appid,
      active: props.appConfig?.active || !props.appConfig?.eventsHeaderActive,
      getBetslipSelectionList: [],
      oddsType: getCookie('selectedOdds') || GLOBAL_CONSTANTS.FRACTIONAL,
      currencyCode: HnaldeDefaultCurrency(),
      isAuthenticated: false,
      carousel: props.appConfig?.carousel,
      hideCategories: props.appConfig?.hidecategory,
      theme: props.appConfig?.theme,
      unsubscribeEventHeader: props.appConfig?.unsubscribeeventheader,
      fallbackUrl: props.appConfig?.fallbackurl,
      userSegment: '',
    };
  }

  componentDidMount = () => {
    const activeHeader = window.localStorage.getItem('lastActiveTab');
    if (activeHeader) {
      if (parseInt(activeHeader) === parseInt(this.state.appid)) {
        this.setState({ active: true });
      } else {
        this.setState({ active: false });
      }
    }
    this.eventAppActive = PubSub.listen(
      PubsubEvents.EventsAppActive,
      wsData => {
        if (wsData.appid === parseInt(this.state.appid)) {
          this.setState({ active: true });
        } else {
          this.setState({ active: false });
        }
      }
    );
    this.state.hideCategories && hideCategoryMenuList();
    // PubSub event listen to get selections added in betslip
    this.betSlipUpdate = PubSub.listen(
      PubsubEvents.EventsAppBetslipUpdate,
      wsData => {
        this.setState({ getBetslipSelectionList: wsData });
      }
    );
    this.oddsValuePubSub = PubSub.listen(PubsubEvents.oddsValue, wsData => {
      this.setState({ oddsType: wsData });
    });
    // when user login
    this.sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      if (UserAuthData && UserAuthData['accessToken']) {
        this.setState({
          currencyCode: UserAuthData.currencyCode,
          isAuthenticated: true,
          userSegment: UserAuthData.segmentRef,
        });
      }
    });

    // when user relogin or reload page
    this.sessionUpdated = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      if (UserAuthData && UserAuthData['accessToken']) {
        this.setState({
          currencyCode: UserAuthData.currencyCode,
          isAuthenticated: true,
          userSegment: UserAuthData.segmentRef,
        });
      }
    });

    // if user is already login
    const UserAuthData = FSBCustomerSession.getSession();
    if (UserAuthData && UserAuthData['accessToken']) {
      this.setState({
        currencyCode: UserAuthData.currencyCode,
        isAuthenticated: true,
        userSegment: UserAuthData.segmentRef,
      });
    }

    // handle when user logout
    this.sessionDestroy = PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
      this.setState({ isAuthenticated: false });
    });
    const items = [];
    // checks for dynamic banner and if available fetch associated markets to the market album
    this.props.appConfig?.items.forEach(async itm => {
      if (
        itm?.appConfig?.type === GLOBAL_CONSTANTS.DYNAMIC &&
        itm?.appConfig?.marketalbumref
      ) {
        //if album ref name added through CMS , fetch details
        const albumRefMarkets = await this.checkForSportsbookAlbum(
          itm?.appConfig?.marketalbumref || []
        );
        let changedItm;
        if (albumRefMarkets?.length > 0) {
          albumRefMarkets.forEach(id => {
            //iterate and add each market as a seperate banner in the list
            changedItm = JSON.parse(JSON.stringify(itm));
            changedItm['appConfig']['marketid'] = id;
            items.push(changedItm);
          });
          this.setStateWithFetchedItems([...items]);
        }
      } else {
        //if no dynamic banner add item to the list directly
        items.push(itm);
      }
    });
    this.setStateWithFetchedItems(items);
  };

  setStateWithFetchedItems = items => {
    this.setState(
      {
        items: this.getFilteredItemsByAuth(items),
      },
      () => this.getPromotionsDynamicData(this.state.items)
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.isAuthenticated !== this.state.isAuthenticated) {
      this.setState(
        {
          items: this.getFilteredItemsByAuth(this.state.items),
        },
        () => this.getPromotionsDynamicData(this.state.items)
      );
    }
  }
  componentWillUnmount() {
    //Unsubscribing from PubSubs
    this.eventAppActive?.unsubscribe();
    this.betSlipUpdate?.unsubscribe();
    this.oddsValuePubSub?.unsubscribe();
    this.sessionCreated?.unsubscribe();
    this.sessionUpdated?.unsubscribe();
    this.sessionDestroy?.unsubscribe();
  }
  getFilteredItemsByAuth = items =>
    items?.filter(itm => {
      const authProp = itm?.appConfig?.auth;
      if (
        (authProp === GLOBAL_CONSTANTS.AUTH && !this.state.isAuthenticated) ||
        (authProp === GLOBAL_CONSTANTS.NOAUTH && this.state.isAuthenticated)
      ) {
        return false;
      }
      return true;
    }) ?? [];

  checkForSportsbookAlbum = async albumName => {
    const url = `/fsb-api-rest/bet/category.fsb?marketAlbumRef=${albumName}`;
    return getRequest(url)
      .then(res => {
        return res?.marketOrder?.split(',');
      })
      .catch(e => {
        console.log(e); // eslint-disable-line no-console
      });
  };

  getPromotionsDynamicData = items => {
    if (items?.length > 0) {
      for (let t = 0; t < items.length; t++) {
        if (items[t]?.appConfig?.type === GLOBAL_CONSTANTS.DYNAMIC) {
          const tempObj = {};
          const url = `/fsb-api-rest/bet/market/${items[t]?.appConfig?.marketid}.fsb`;
          getRequest(url)
            .then(res => {
              if (res?.category) {
                res?.category?.forEach(cat => {
                  tempObj.sportName = cat.name;
                  tempObj.ref = cat.ref;
                  cat?.subcat?.forEach(sub => {
                    tempObj.leagueName = sub.name;
                    sub?.event?.forEach(evnt => {
                      tempObj.eventName = evnt.name;
                      tempObj.scheduledStart = evnt.scheduledStart;
                      tempObj.state = evnt.state;
                      tempObj.subCatUrl = `${cat.ref}/${sub.ref}/`;
                      tempObj.eventUrl = `${cat.ref}/${sub.ref}/${evnt.id}/`;
                      tempObj.providerRef = evnt.providerRef;
                      tempObj.sourceKey = evnt.sourceKey;
                      evnt?.market?.forEach(mrkt => {
                        tempObj.marketName = mrkt.name;
                        tempObj.active = mrkt.active;
                        tempObj.selections = [];
                        mrkt?.selection?.forEach(slctn => {
                          slctn?.price?.forEach(prce => {
                            const sampleSelection = {
                              selectionName: slctn.name,
                              active: slctn.active,
                              selectionId: slctn.id,
                              decimal: '',
                              fractional: '',
                              typeRef: slctn.typeRef,
                            };
                            if (prce.bookType === GLOBAL_CONSTANTS.PRICE) {
                              sampleSelection.decimal = prce.decimal;
                              sampleSelection.fractional = prce.fractional;
                            }
                            tempObj.selections.push(sampleSelection);
                          });
                        });
                      });
                    });
                  });
                });
                items[t].appConfig['dynamicData'] = tempObj;
                this.setState({ items: items });
              }
            })
            .catch(error => {
              return error;
            });
        }
      }
    }
  };

  //to filter banners based on user segment
  //visibletohavingsegment is a string containing comma seperated list of segment
  getItemList = items =>
    items.filter(itm => {
      if (itm.appConfig?.visibletohavingsegment) {
        if (this.state.userSegment) {
          const segmentsAllowed = itm.appConfig.visibletohavingsegment.split(
            ','
          );
          if (segmentsAllowed.includes(this.state.userSegment)) {
            return itm;
          }
        }
      } else {
        return itm;
      }
    });

  render() {
    const items = this.getItemList(this.state.items);
    const getBetslipSelectionList = this.state.getBetslipSelectionList;
    const oddsType = this.state.oddsType;
    const isCarousel = this.state.carousel;
    return (
      <FSBTheme theme={this.state.theme}>
        {(this.state.active || this.state.unsubscribeEventHeader) && (
          <React.Fragment>
            <PromotionsGridContainer hideCategories={this.state.hideCategories}>
              <PromotionsContainer
                carouselActive={isCarousel}
                items={[...items]}
                getBetslipSelectionList={getBetslipSelectionList}
                oddsType={oddsType}
                hideCategories={this.state.hideCategories}
                currencyCode={this.state.currencyCode}
                fallbackUrl={this.state.fallbackUrl}
                disableLeftMargin={this.props.appConfig?.disableleftmargin}
                isAuthenticated={this.state.isAuthenticated}
              />
            </PromotionsGridContainer>
          </React.Fragment>
        )}
        <React.Fragment />
      </FSBTheme>
    );
  }
}

PromotionsGridReact.propTypes = {
  appConfig: PropTypes.object,
};
export default PromotionsGridReact;
