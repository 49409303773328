import {
  FD,
  ID,
  SD,
  ZD,
  COB,
  FP,
  CCD,
  W,
} from 'CORE__UI/apps/PromotionsCarousel/core__slickCarousel';
import styled from 'styled-components';
import { FONT, GREYS } from '../../globals/colours';

const FD_PT = styled(FD)`
  font-size: 14px;
  color: ${({ imageurl }) => (imageurl ? `${FONT.light}` : `${FONT.darkMute}`)};
`;

const CCD_H = styled(CCD)`
  color: ${({ imageurl, theme }) =>
    theme.dark ? FONT.primary : imageurl ? `${FONT.light}` : `${GREYS.black}`};

  & > div:first-child {
    color: ${({ imageurl }) =>
      imageurl ? `${FONT.light}` : `${FONT.darkMute}`};
  }

  font-weight: ${({ imageurl }) => (imageurl ? 400 : 700)};
`;

const ZD_T = styled(ZD)`
  border: 1px solid ${FONT.lightMute};
  background-color: ${FONT.light};
`;

const ID_T = styled(ID)`
  font-weight: none;
`;

const SD_T = styled(SD)`
  font-size: ${({ resultAccept }) => (resultAccept ? '14px' : '16px')};
  color: ${({ imageurl, resultAccept }) =>
    !imageurl && resultAccept && `${FONT.darkMute}`};
  margin-bottom: 6px;
`;

export {
  FD_PT as FD,
  ID_T as ID,
  SD_T as SD,
  ZD_T as ZD,
  COB,
  FP,
  CCD_H as CCD,
  W,
};
